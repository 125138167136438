<template>
  <div class="w-full sm:flex md:hidden lg:flex">
    <div
        class="w-full h-[800px] md:h-[369px] lg:h-[292px] flex flex-col p-9 sm:p-12 md:mb-0 justify-start items-start md:px-6 lg:px-12 xlg:px-[64px]"
        :class="background ? 'bg-' + background : 'bg-[#16131b]'">
      <div class="flex flex-col items-start w-full lg:flex-row justify-evenly md:items-start">
        <div class="flex flex-col items-start justify-start md:flex-row md:items-start md:w-full ">
          <div class="flex flex-col justify-start items-start p-0 gap-1 lg:h-[244px] ">
            <!-- <img src="@/assets/images/homepage/obb-logo-horizontal.svg"
              class="w-[162px] h-[35px] lg:w-[152px] lg:h-[32px] lg:mr-3 ml-3 cursor-pointer" alt="img"
              @click="toHomePage" /> -->
            <a :href="goToWebsite()">
              <img src="@/assets/svgs/ohbbg_logo.svg"
                 class="w-[64px] lg:mr-3 cursor-pointer" alt="img" />
            </a>
            <div class="z-50 hidden w-56 mt-5 overflow-hidden lg:flex ">
              <!-- <img src="@/assets/images/footer-inverse-bat.svg" class="lg:w-[226px] md:w-[230px]" alt="img"> -->
            </div>
          </div>
          <div
              class="flex flex-col md:flex-row justify-center h-full lg:h-[167.45px] gap-8 xl:space-x-[78.15px] md:gap-20 items-start w-full mt-12 md:mt-0 whitespace-nowrap">
            <!-- <div class="flex flex-col items-center justify-center h-full gap-3 md:justify-start md:items-start">
              <span class="text-sm font-bold text-white font-openSans" style="white-space: nowrap;">Made by OBB</span>
              <span class="text-xs font-normal text-white font-openSans">Oh Baby! Kart</span>
              <span class="text-xs font-normal text-white font-openSans">Store</span>
            </div> -->

            <div class="flex flex-col items-start justify-start h-full gap-3 md:justify-start md:items-start">
              <span class="text-xs font-medium text-[#9C93B3] font-Rubik uppercase tracking-[1.5px]" style="white-space: nowrap">Games</span>
              <span class="text-xs font-normal text-mainText font-Rubik">Oh Baby! Kart</span>
              <span class="text-xs font-normal text-mainText font-Rubik">Shogun curse</span>
              <span class="font-normal text-[#9c93b34d] text-xs font-Rubik">Oh Baby! Party</span>
            </div>

            <div class="flex flex-col items-start justify-center h-full gap-3 md:justify-start md:items-start">
              <span class="text-xs font-medium text-[#9C93B3] font-Rubik uppercase tracking-[1.5px]">Resources</span>
              <a :href="goToWebsite('')" class="text-xs cursor-pointer text-mainText font-openSans">Website</a>
              <a :href="goToWebsite('/support')" class="text-xs cursor-pointer text-mainText font-Rubik">Support</a>
              <a :href="goToWebsite('/about-us')" class="text-xs cursor-pointer text-mainText font-Rubik">Company</a>
              <a :href="goToWebsite('/news')" class="text-xs cursor-pointer text-mainText font-Rubik">News</a>
              <a :href="goToWebsite('/careers')" class="text-xs cursor-pointer text-mainText font-Rubik">Work with Us</a>
              <a :href="goToWebsite('/developers')" class="text-xs cursor-pointer text-mainText font-Rubik">Partner With Us</a>

            </div>

            <div class="flex flex-col items-start justify-center h-full gap-3 md:justify-start md:items-start">
              <span class="text-xs font-medium text-[#9C93B3] font-Rubik uppercase tracking-[1.5px]">Policies</span>
              <a :href="goToWebsite('/terms-of-service')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Terms of Service</a>
              <a :href="goToWebsite('/privacy-policy')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Privacy Policy</a>
              <a :href="goToWebsite('/cookie-policy')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Cookie Policy</a>
              <a :href="goToWebsite('/acceptable-user-policy')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Acceptable Use Policy</a>
              <a :href="goToWebsite('/disclaimer')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Disclaimer</a>
            </div>
          </div>
        </div>

        <div class="flex md:flex-row md:content-between md:w-full md:h-[150px] lg:h-[59px] lg:w-[300px] lg:mt-0">
          <div class="z-50 hidden overflow-hidden md:flex lg:hidden">
            <img src="@/assets/images/footer-inverse-bat.svg" class="lg:w-[226px] md:w-[230px] object-cover" alt="img" />
          </div>

          <div class="flex flex-col justify-center items-start md:items-end md:w-full lg:w-[300px] gap-6 lg:mt-0 mt-11">
            <div class="flex flex-row items-center justify-center gap-3 p-0 pr-2 md:justify-end">
              <a class="w-[24px] h-[18.58px]" href="https://twitter.com/OhBabyGames" target="_blank"><img
                  src="@/assets/images/homepage/twitter-icon.svg" alt="" /></a>
              <a class="w-[24px] h-[18.58px]" href="https://instagram.com/ohbabygames" target="_blank"><img
                  src="@/assets/images/homepage/ig-icon.svg" alt="" /></a>
              <a class="w-[24px] h-[18.58px]" href="https://www.youtube.com/@ohbabygames/featured" target="_blank"><img
                  src="@/assets/images/homepage/yt-icon.svg" alt="" /></a>
              <a class="w-[24px] h-[18.58px]" :href="goToWebsite('/discord-join')" target="_blank"><img
                  src="@/assets/images/homepage/discord-icon.svg" alt="" /></a>
            </div>
            <span class="text-right text-[#9C93B3] text-xs font-semibold font-['Open Sans']" style="white-space: nowrap">
              Oh Baby Games ©2024, All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TABLET FOOTER -->
  <div class="hidden w-full md:flex lg:hidden">
    <div
        class="w-full h-[900px] md:h-[369px] lg:h-[280px] flex flex-row items-start pt-9 md:mb-0 justify-between md:px-[37px] lg:px-12 xlg:px-24"
        :class="background ? 'bg-' + background : 'bg-[#16131b]'">
      <div class="flex flex-col justify-between h-full items-between">
        <div class="w-[162px] h-[35px] relative">
          <!-- <img src="@/assets/images/homepage/obb-logo-horizontal.svg"
            class="w-[162px] h-[35px] lg:w-[152px] lg:h-[32px] lg:mr-3" alt="img" /> -->
          <img src="@/assets/svgs/ohbbg_logo.svg"
               class="w-[64px] lg:mr-3 ml-3 cursor-pointer" alt="img"
               @click="toHomePage" />
        </div>
        <!-- <div>
          <img class="w-[226px] max-h-[335px] ml-[-25px]" src="@/assets/images/footer-inverse-bat.svg" />
        </div> -->
      </div>

      <div class="flex flex-col justify-between h-full items-between">
        <!-- MID SECTION -->
        <div class="w-[451px] h-[184px] justify-start items-start gap-[35px] inline-flex mr-8">
          <div class="w-[137.51px] h-[52.33px] relative flex flex-col">
            <span class="text-xs font-medium text-[#9C93B3] font-Rubik uppercase tracking-[1.5px] mb-[10px]" style="white-space: nowrap">Games</span>
            <div class="w-[137.51px] text-white text-sm font-normal font-Rubik leading-[23px]">
              <a class="text-xs font-normal text-mainText font-Rubik">Oh Baby! Kart</a><br>
              <a class="text-xs font-normal text-mainText font-Rubik">Shogun curse</a><br>
              <a class="font-normal text-[#9c93b34d] text-xs font-Rubik">Oh Baby! Party</a>
            </div>
          </div>
          <div class="w-[137.51px] h-[184px] relative">
            <div class="text-xs font-medium text-[#9C93B3] font-Rubik uppercase tracking-[1.5px] mb-[10px]">
              Resources
            </div>
            <div class="w-[137.51px] text-white text-sm font-normal font-Rubik leading-[23px]">
              <a :href="goToWebsite('')" class="text-xs text-white cursor-pointer font-Rubik">Website</a><br/>
              <a :href="goToWebsite('/support')" class="text-xs cursor-pointer text-mainText font-Rubik">Support</a><br />
              <a :href="goToWebsite('/about-us')" class="text-xs cursor-pointer text-mainText font-Rubik">Company</a><br />
              <a :href="goToWebsite('/news')" class="text-xs cursor-pointer text-mainText font-Rubik">News</a><br />
              <a :href="goToWebsite('/careers')" class="text-xs cursor-pointer text-mainText font-Rubik">Work with Us</a><br />
              <a :href="goToWebsite('/developers')" class="text-xs cursor-pointer text-mainText font-Rubik">Partner With Us</a><br />
            </div>
          </div>

          <div class="w-[137.51px] h-[175px] relative">
            <div class="w-[137.51px] text-white text-sm font-normal font-['Open Sans'] leading-[23px]">
              <div class="text-xs font-medium text-[#9C93B3] font-Rubik uppercase tracking-[1.5px] mb-[10px]">
                Policies
              </div>
              <a :href="goToWebsite('/terms-of-service')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Terms of Service</a><br />
              <a :href="goToWebsite('/privacy-policy')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Privacy Policy</a><br />
              <a :href="goToWebsite('/cookie-policy')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Cookie Policy</a><br />
              <a :href="goToWebsite('/acceptable-user-policy')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Acceptance Use Policy</a><br />
              <a :href="goToWebsite('/disclaimer')"
                 class="text-xs cursor-pointer text-mainText font-Rubik" target="_blank">Disclaimer</a><br />
            </div>
          </div>
        </div>

        <!-- SOCIALS AND COPYRIGHT -->
        <div class="flex flex-col lg:flex-row items-end mb-[42px]">
          <div class="w-[142px] h-6 justify-start items-start gap-[15.25px] inline-flex mb-[5px]">
            <a class="w-[24px] h-[18.58px]" href="https://twitter.com/OhBabyGames" target="_blank">
              <img src="@/assets/images/homepage/twitter-icon.svg" alt="x-icon" />
            </a>
            <a class="w-[24px] h-[18.58px]" href="https://instagram.com/ohbabygames" target="_blank">
              <img src="@/assets/images/homepage/ig-icon.svg" alt="ig-icon" />
            </a>
            <a class="w-[24px] h-[18.58px]" href="https://www.youtube.com/@ohbabygames/featured" target="_blank">
              <img src="@/assets/images/homepage/yt-icon.svg" alt="yt-icon" />
            </a>
            <a class="w-[24px] h-[18.58px]" :href="goToWebsite('/discord-join')" target="_blank">
              <img src="@/assets/images/homepage/discord-icon.svg" alt="discord-icon" />
            </a>
          </div>

          <div class="text-[#9C93B3] text-xs font-normal font-Rubik">
            Oh Baby Games ©2024, All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import envConfig from '../config';
import axios from 'axios';

export default {
  props: ["background", "color"],

  data() {
    return {
      userToken: null,
      userData: null,
      allow_store: false,
    }
  },

  mounted() {
    this.userToken = window.getCookie('obb_ck')
    this.fetchUser()
  },

  methods: {
    toHomePage() {
      window.location.href = "/";
    },

    gotoStore() {
      window.location.href = envConfig.storeUrl
    },

    // getRouteHref(route) {
    //   return this.$router.resolve(route).href;
    // },

    fetchUser() {
      const url = envConfig.baseUrl + '/api/user';

      if (this.userToken) {
        axios.get(url, {
          headers: {
            'Authorization': `Bearer ${this.userToken}`,
            'Content-Type': 'application/json',
          }
        })
            .then(response => {
              const data = response.data;
              this.userData = data.data
              if (this.userData.allow_store) {
                this.allow_store = true;
              }
            })
            .catch(error => {
              console.log(error)
            });
      }
    },

    goToWebsite(relPath) {
      if (relPath) {
        return envConfig.websiteUrl + relPath;
      } else {
        return envConfig.websiteUrl;
      }
    },
  },
};
</script>
